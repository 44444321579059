<template>
    <div id="wrapper">
        <navbar id="nav" class="animate__animated animate__fadeInLeft no-print"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn/>
                <div class="container-fluid">
                    <h3 class="text-dark multi-language mt-1-print"> {{ employeeLangauge[language].employees }} </h3>
                    <hr>
                    <div class="row" style="overflow-x: auto;">
                        <select v-model="active_status" class="form-control col-5 mr-1">
                            <option value="1">Actived</option>
                            <option value="0">Deactived</option>
                        </select>
                        <select v-model="staff_id" class="form-control col-5 ml-1">
                            <option value="">  </option>
                            <option v-for="staff in staffs" :key="'staff' + staff.st_id" :value="staff.st_id"> {{ staff.staff_name}} </option>
                        </select>
                        <table class="table table-bordered table-striped text-dark text-center mt-4">
                            <thead>
                                <th> Name </th>
                                
                                <th>{{ employeeLangauge[language].staff }}</th>
                                <th >{{ employeeLangauge[language].phone }}</th>
                                <th> Phone 2 </th>
                                <th> Blood Group </th>
                                
                                <th class="no-print" style="width: 6%;"> Actions </th>
                            </thead>
                            <tbody>
                                <tr v-for="employee in employees" :key="employee.emp_id">
                                    <td> {{ employee.first_name + ' ' + employee.last_name }} </td>
                                    <td> {{ employee.staff_name }} </td>
                                    <td> {{ employee.phone }} </td>
                                    <td> {{ employee.phone2 }} </td>
                                    <td> {{ employee.blood_group }} </td>
                                    
                                    <td class="no-print">
                                        <button class="btn btn-primary" v-on:click="get_employee(employee.emp_id)" data-toggle="modal" data-target="#mdl_employee"> <i class="fa fa-eye"></i> </button> 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                </div>
            </div>
        </div>

        <!-- Modals -->
        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_employee">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2">  {{ employeeLangauge[language].employee_information }} </h5>
                    </div>
                    <div class="modal-body " >
                        <div class="container-fluid">

                            <div class="w-100 d-flex justify-content-center mb-4">
                                <img v-if="single_employee.personal_image_path" width="180px;" class="mx-auto" :src="$image_path + '/employee/personal_image/' + single_employee.personal_image_path">
                            </div>
                            
                            <div class="row">
                                
                                <label class="col-sm-3 text-center mt-2">  {{ employeeLangauge[language].first_name }} : </label>
                                <label class="col-sm-9 form-control text-center"> {{ single_employee.first_name }} </label>

                                <label class="col-sm-3 text-center mt-2">  {{ employeeLangauge[language].last_name }} : </label>
                                <label class="col-sm-9 form-control text-center"> {{ single_employee.last_name }} </label>

                                <label class="col-sm-3 text-center mt-2"> {{ employeeLangauge[language].staff }} : </label>
                                <label class="col-sm-9 form-control text-center"> {{ single_employee.st_id ? staffs.find(obj => obj.st_id == single_employee.st_id).staff_name : '' }} </label>

                                <label class="col-sm-3 text-center mt-2">  {{ employeeLangauge[language].phone }} : </label>
                                <label class="col-sm-9 form-control text-center"> {{ single_employee.phone }} </label>

                                <label class="col-sm-3 text-center mt-2">  Phone 2: </label>
                                <label class="col-sm-9 form-control text-center"> {{ single_employee.phone2 }} </label>

                                <label class="col-sm-3 text-center mt-2" > Family Number: </label>
                                <label class="col-sm-9 form-control text-center" > {{ single_employee.family_number_1 }} </label>

                                <label class="col-sm-3 text-center mt-2" > Family Number 2: </label>
                                <label class="col-sm-9 form-control text-center" > {{ single_employee.family_number_2 }} </label>
                                
                                <label class="col-sm-3 text-center mt-2"> {{ employeeLangauge[language].country }} : </label>
                                <label class="col-sm-9 form-control text-center"> {{ single_employee.country | country_filter }} </label>

                                <label class="col-sm-3 text-center mt-2"> Job : </label>
                                <label class="col-sm-9 form-control text-center"> {{ single_employee.job }} </label>

                                <label class="col-sm-3 text-center mt-2" v-if="single_employee.country != '1'">   {{ employeeLangauge[language].expire_passport }} : </label>
                                <label class="col-sm-9 form-control text-center" v-if="single_employee.country != '1'"> {{ single_employee.expiry_passport ? new Date(single_employee.expiry_passport).toISOString().split('T')[0] : '' }} </label>

                                <label class="col-sm-3 text-center mt-2">  {{ employeeLangauge[language].registration_date }} : </label>
                                <label class="col-sm-9 form-control text-center"> {{ single_employee.reg_date ? new Date(single_employee.reg_date).toISOString().split('T')[0] : '' }} </label>
                                
                                
                                <label class="col-sm-3 text-center mt-2" > Asaish Code : </label>
                                <label class="col-sm-9 form-control text-center" > {{ single_employee.asaish_code }} </label>

                                <label class="col-sm-3 text-center mt-2" > Car : </label>
                                <label class="col-sm-9 form-control text-center" > {{ single_employee.car }} </label>
                                
                                <label class="col-sm-3 text-center mt-2" > Car Number : </label>
                                <label class="col-sm-9 form-control text-center" > {{ single_employee.car_number }} </label>

                                <label class="col-sm-3 text-center mt-2" > Location : </label>
                                <label class="col-sm-9 form-control text-center" > {{ single_employee.living_location }} </label>
                                
                                <label class="col-sm-3 text-center mt-2">  {{ employeeLangauge[language].salary_type}} : </label>
                                <label class="col-sm-9 form-control text-center"> {{ single_employee.salary_type }} </label>
                                
                                <label class="col-sm-3 text-center mt-2">  {{ employeeLangauge[language].food }} : </label>
                                <label class="col-sm-9 form-control text-center" dir="ltr"> {{ (single_employee.food_money || 0).toLocaleString() }} <span> IQD. </span> </label>
                                
                                <label class="col-sm-3 text-center mt-2">  {{ employeeLangauge[language].transport }} : </label>
                                <label class="col-sm-9 form-control text-center" dir="ltr"> {{ (single_employee.transport_money || 0).toLocaleString() }} <span> IQD. </span> </label>
                                
                                <label class="col-sm-3 text-center mt-2">  Cabina : </label>
                                <label class="col-sm-9 form-control text-center" dir="ltr"> {{ (single_employee.cabina_money || 0).toLocaleString() }} <span> IQD. </span> </label>
                                
                                <label class="col-sm-3 text-center mt-2">  Loan : </label>
                                <label class="col-sm-9 form-control text-center" dir="ltr"> {{ (single_employee.loan_money || 0).toLocaleString() }} <span> IQD. </span> </label>
                                
                                <label class="col-sm-3 text-center mt-2">  Accomodation : </label>
                                <label class="col-sm-9 form-control text-center" dir="ltr"> {{ (single_employee.accomodation_money || 0).toLocaleString() }} <span> IQD. </span> </label>
                                
                                <label class="col-sm-3 text-center mt-2">  Fine : </label>
                                <label class="col-sm-9 form-control text-center" dir="ltr"> {{ (single_employee.fine_money || 0).toLocaleString() }} <span> IQD. </span> </label>
                                
                                <label class="col-sm-3 text-center mt-2">  Salary Per Month : </label>
                                <label class="col-sm-9 form-control text-center" dir="ltr"> {{ (single_employee.other_expense || 0).toLocaleString() }} <span> IQD. </span> </label>
                                
                                <label class="col-sm-3 text-center mt-2">  {{ employeeLangauge[language].salary }} : </label>
                                <label class="col-sm-9 form-control text-center" dir="ltr" v-if="single_employee.salary_type == 'Monthly'"> {{ (single_employee.monthly_salary || 0).toLocaleString() }} $ </label>
                                <label class="col-sm-9 form-control text-center" dir="ltr" v-else> {{ (single_employee.daily_salary || 0).toLocaleString() }} IQD </label>

                                <label class="col-sm-3 text-center mt-2">  {{ employeeLangauge[language].hour_salary }} : </label>
                                <label class="col-sm-9 form-control text-center" dir="ltr">
                                    {{ single_employee.hour_salary }}  
                                    <span v-if="single_employee.salary_type == 'Monthly'"> $ </span>
                                    <span v-else> IQD </span>
                                </label>

                                <label class="col-sm-3 text-center mt-2" > First work date: </label>
                                <label class="col-sm-9 form-control text-center" > {{ single_employee.first_work_date ? new Date(single_employee.first_work_date).toISOString().split('T')[0] : '' }} </label>
                                
                                <label class="col-sm-3 text-center mt-2" > Blood Group: </label>
                                <label class="col-sm-9 form-control text-center" > {{ single_employee.blood_group }} </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import auth from '../../auth.js'
    import $ from 'jquery';
    import { Bus } from '../../Bus.js';
    import employeeLangauge from '../../languages/employees.js';
    export default {
        name: "employeeReport",
        data() {
            return {
                noOfEmployees: 0,
                employees: [],
                active_status: '1',
                single_employee: {
                    
                },
                search: "",
                current_pagination: 1,
                max_pagination: 0,
                employeeLangauge,
                language:'English',
                user: {
                    username: '',
                    role: '',
                    en_id: null
                },
                staffs: [],
                staff_id: null,
            }
        },
        beforeCreate() {
            auth("employeeReport");
        },
        created() {
            this.$http.post("/getLoggedInfo").then(({data}) => {
                this.user.username = data.username;
                this.user.role = data.type;
                this.user.en_id = data.en_id || null;

                if(this.user.en_id) {
                    this.$http.get('/employee/getEmployeeByEngineer/' + this.user.en_id).then(({data}) => {
                        this.employees = data;
                    });
                } else {
                    this.$http.post('/employee/getActived').then(({data}) => {
                        this.employees = data;
                    });
                }
            });

            this.$http.post('/staff/getData').then(({data})=>{
                this.staffs = data.filter(obj => obj.show_staff == '1');
            })
        },
        mounted() {
            
            Bus.$on("languageChanged", (data) => {
                this.language = data;
                if(["Kurdish", "Arabic"].includes(data)){
                    $(".multi-language").addClass("rtl");
                    $("#wrapper").attr("dir", "rtl")
                } else {
                    $(".multi-language").removeClass("rtl");
                    $("#wrapper").attr("dir", "ltr")
                }
            });
        },
        methods: {
            getData() {
                
                this.$http.post('/employee/getForReport?active_status=' + this.active_status +( this.staff_id ? ('&st_id=' + this.staff_id) : '')).then(({data}) => {
                    this.employees = data;
                });
                
            },
            
            get_employee(emp_id) {
                this.$http.get('/employee/getById/' + emp_id).then(({data}) => {
                    this.single_employee = data.data;
                });
            }
            
            
        },
        filters: {
            status_filter(value,language) {
                return [
                  employeeLangauge[language].deactive,
                  employeeLangauge[language].active,
                ][value];
            },
            
            salary_type_filter(value) {
                if(value == "Monthly"){
                    return "Monthly";
                }
                return "Daily";
            },
            country_filter(value) {
                return {
                    "1": "Iraq",
                    "2": "Foreign",
                    "3": "Iran",
                    "4": "Bangladish",
                    "5": "Syria",
                    "6": "India",
                    "7": "Pakistan",
                }[value]
            }
        },
        watch: {
            staff_id() {
                this.getData();
            },
            active_status() {
                this.getData();
            }
        }
    }
</script>

<style scoped>
    @font-face {
        font-family: nrt;
        src: url("../../assets/fonts/NRT-Reg.ttf");
    }
    table, h3, .modal {
        font-family: nrt;
    }
    @media print {
        input, button, .no-print {
            display: none !important;
        }
        .mt-1-print {
            margin-top: 8px !important;
        }
        @page {
            padding: 0;
            margin: 0;
            size: A4;
        }
        #wrapper #content-wrapper {
            background-color: white !important;
        }
    }
    .rtl {
    text-align: right !important;
  }
</style>